import React, {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/login.css'

import Toast from '../components/toast/Toast';

import md5 from 'md5';

import { Button, TextField, Typography } from '@mui/material';

import LoadingSplash from '../components/LoadingSplash/LoadingSplash';
import { api } from '../apis/connect';
import { useAuth } from '../contexts/AuthContext';

import ModalCadastro from '../components/modals/cadastro/ModalCadastro';

function Login() {

    const { login } = useAuth();

    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [modalCadastro, setModalCadastro] = useState(false);

    const handleLogin = (e) => {
        e.preventDefault();

        setLoading(true);

        api.login(email, md5(password)).then(response => {
            
            if (response.error) {
                Toast.error(response.error);
                setLoading(false);
                return;
            }

            login(response.token);
            Toast.success('Login realizado com sucesso!');

            setLoading(false);

            window.location.href = '/';

        }).catch(error => {
            Toast.error('Erro ao realizar login');
        });
    };

    useEffect(() => {

    }, [])

    return(
        <div>

            <section class="tela-1 d-flex justify-content-center text-center align-items-center flex-column">

                <div>
                    <h3>Login de Afiliado</h3>
                    <div class="lead mb-3">Preencha os campos abaixo para acessar o sistema</div>

                    <form name='login' onSubmit={e => handleLogin(e)}>

                        <TextField
                            label="Email"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type='email'
                        />

                        <TextField
                            label="Senha"
                            type="password"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='mb-2'
                        />

                        <div class='d-flex align-items-center justify-content-center'>

                            <Button className='py-3 px-5 me-3' variant="contained" type='submit' color="success">
                                Entrar
                            </Button>

                            <button type="button" className='py-3 px-4 mt-3 my-3 btn btn-outline-success' onClick={() => setModalCadastro(true)}>
                                Criar conta
                            </button>

                        </div>


                    </form>
                    
                </div>

            </section>

            <LoadingSplash active={loading}/>

            <ModalCadastro 
            show={modalCadastro} 
            onHide={() => setModalCadastro(false)}
            callback={(dados) => {
                    setEmail(dados.email);
                    setPassword(dados.password);
                }
            }
            >
            </ModalCadastro>

        </div>
    )
}

export default Login