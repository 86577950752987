import React, {useState, useEffect, useRef} from 'react'

import LoadingSplash from '../components/LoadingSplash/LoadingSplash';
import Toast from '../components/toast/Toast';
import MyTable from '../components/mytable/MyTable';

import { api } from '../apis/connect';
import { useAuth } from '../contexts/AuthContext';

import {mask_money, mask_input_money} from '../utils/masks';
import { Check, Clear, HourglassEmpty } from '@mui/icons-material';

function Saques() {

    const { usuarioLogado } = useAuth();

    const [saques, setSaques] = useState([]);
    const [saldo, setSaldo] = useState(0);
    const [valor, setValor] = useState(null);

    const [replay, setReplay] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);

        usuarioLogado?.id && api.saques.getAll().then(res => {

            if (res.error) {
                Toast.error(res.error);
                setLoading(false);
                return;
            }

            setSaques(res);
            setLoading(false);
        });

        usuarioLogado?.id && api.afiliado.saldo(usuarioLogado.id).then(res => {

            if (res.error) {
                Toast.error(res.error);
                return;
            }

            setSaldo(res.saldo);
        });

    }, [usuarioLogado, replay])

    function handlerSubmit(event) {
        event.preventDefault();

        if (valor >= saldo) {
            Toast.error('Saldo insuficiente');
            return;
        }

        if (valor <= 0) {
            Toast.error('Valor inválido');
            return;
        }

        setLoading(true);

        api.saques.solicitar(valor.replace('.', '').replace(',', '.')).then(res => {

            if (res.error) {
                Toast.error(res.error);
                setLoading(false);
                return;
            }

            Toast.success('Saque solicitado com sucesso!');
            setLoading(false);
            setReplay(replay+1);
        });

    }

    const columns = [
        {Header: 'ID', accessor: 'id'},
        {Header: 'Valor', accessor: (row) => mask_money(row.valor)},
        {Header: 'Data de pagamento', accessor: (row) => row.paid_at ? new Date(row.paid_at).toLocaleString('pt-br') : 'Não pago'},
        {Header: 'Data de criação', accessor: (row) => new Date(row.created_at).toLocaleString('pt-br')},
        {Header: 'Motivo', accessor: 'motivo'},
        {Header: 'Status', accessor: (row) => {

            if (row.paid) {
                return <div class='btn btn-success text-white d-flex justify-content-center align-items-center'> <Check fontSize='small' className='me-2'/> Pago</div>
            } else if (row.cancelado) {
                return <div class='btn btn-danger text-white d-flex justify-content-center align-items-center'> <Clear fontSize='small' className='me-2'/> Cancelado</div>
            } else {
                return <div class='btn btn-info text-white d-flex justify-content-center align-items-center'> <HourglassEmpty fontSize='small' className='me-2'/> Aguardando aprovação</div>
            }
        
        }},
    ]

    return(
        <div>

            <section class="tela-1 px-sm-5 mt-5 mt-sm-2">

                <div class='row text-center'>

                    <div class='col me-4'>
                        <h3>
                            Solicitações de saque
                        </h3>
                        <p>Crie, gerencie e veja os relatórios de seus pedidos de saque</p>
                    </div>

                </div>

                <div class='row text-center'>
                    
                    <div class='col-12 mt-5'>

                        <div class='row'>

                            <form class='col' name='saque' aria-label='Saques' onSubmit={handlerSubmit}>

                                <div class="mb-4">
                                    <div class='fs-5'>Solicitar saque:</div>
                                </div>

                                <div class="row justify-content-center align-items-center">

                                    <div class="col-4">
                                        <label for="valor" class="form-label">Valor:</label>
                                        <div class="input-group">
                                            <span class="input-group-text">R$</span>
                                            <input class='form-control' name='valor' type='text' placeholder='0,00' value={valor ? valor.toLocaleString() : ''} onChange={(event) => {
                                                mask_input_money(event);
                                                setValor(event.target.value);
                                            }} required></input>
                                        </div>
                                        <div id="saldo" class="form-text">Saldo: {mask_money(saldo)}</div>
                                    </div>

                                    <button type="submit" class="col-1 mt-2 btn btn-success text-center">Sacar</button>

                                </div>


                            </form>

                            <div class='col-12 mt-5'>
                                <div class='fs-5 mb-3'>Suas solicitações:</div>
                                <MyTable columns={columns} data={saques}/>
                            </div>

                        </div>
                    </div>


                </div>



            </section>

            <LoadingSplash active={loading}/>

        </div>
    )
}

export default Saques