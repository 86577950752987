// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

function decodeToken(token, notparse = false) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    if (notparse) {
        return jsonPayload;
    }

    return JSON.parse(jsonPayload);
}

export const AuthProvider = ({ children }) => {
    const [usuarioLogado, setUsuarioLogado] = useState(null);
    const [token, setToken] = useState(null);

    useEffect(() => {

        let usuario = localStorage.getItem('usuario');
        let user_parsed = null;
        if (usuario) {
            user_parsed = JSON.parse(usuario);
            setUsuarioLogado(user_parsed);
        }

        let token = localStorage.getItem('token');
        if (token) {

            let data_token = decodeToken(token);
            // if (data_token && data_token.exp && data_token.exp < Date.now() / 1000) {
            //     logout();
            // }
    
            if (data_token && data_token?.id !== user_parsed?.id) {
                logout();
            }

            setToken(token);

        }


    }, []);

    const login = (token) => {

        let jsonPayload = decodeToken(token, true);
        let usuario = JSON.parse(jsonPayload);

        localStorage.setItem('usuario', jsonPayload);
        setUsuarioLogado(usuario);

        localStorage.setItem('token', token);
        setToken(token);
    };

    const logout = () => {

        localStorage.removeItem('usuario');
        setUsuarioLogado(null);

        localStorage.removeItem('token');
        setToken(null);

        window.location.href = '/';

    };

    const isUsuarioLogado = () => {
        // return usuarioLogado !== null;
        return token !== null;
    };

    const authContextValue = {
        usuarioLogado,
        token,
        login,
        logout,
        isUsuarioLogado,
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const isAuthenticaded = () => {
    
    let token = localStorage.getItem('token');
    if (token) {
        return true;
    } else {
        return false;
    }

};
