import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import './menu.css';

import SemLogo from '../../assets/imgs/menu/semlogo.png';
import Logo from '../../assets/imgs/logos/3.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import ModalCadastro from '../modals/cadastro/ModalCadastro';

import { useAuth } from '../../contexts/AuthContext';

function Menu(){

    const [imagem, setImagem] = useState(Logo);
    const [menu, setMenu] = useState(false);
    const [modalCadastro, setModalCadastro] = useState(false);

    const { isUsuarioLogado, logout } = useAuth();

    useEffect(() => {

    }, []);

    function redirect(path){

        if (window.location.pathname === path) return
        window.location.href = path;

    }

    window.addEventListener('click', function(e){
        const header_ul = document.querySelector('header ul');
        header_ul.classList.toggle('active', menu);
    });

    return(
        <header>
            <Link to='/' className="logo"><img src={imagem} alt="Logo" class="sem-logo"/></Link>
            {!isUsuarioLogado() && <ul class="navigation w-100 ps-sm-5 pe-sm-5">
                {/* <li><input type='text' placeholder='Search'></input></li> */}
                <li class='btn btn-outline-success ms-auto me-sm-3' onClick={() => redirect('/login')}>Acessar</li>
                <li class='btn btn-success' onClick={() => setModalCadastro(true)}>Criar conta</li>
            </ul>}

            {isUsuarioLogado() && <ul class="navigation w-100 ps-sm-5 pe-sm-5">
            <li class='btn btn-outline-success ms-auto me-sm-3' onClick={() => redirect('/')}>Home</li>
            <li class='btn btn-outline-success me-sm-3' onClick={() => redirect('/solicitacoes-de-saques')}>Solicitações de saque</li>
                <li class='btn btn-success px-4' onClick={() => logout()}>Sair</li>
            </ul>}

            {!menu ? <MenuIcon className="toggle-rh" onClick={() => setMenu(!menu)} alt=''></MenuIcon>
            :
            <CloseIcon className="toggle-rh" onClick={() => setMenu(!menu)} alt=''></CloseIcon>}

            <ModalCadastro show={modalCadastro} onHide={() => {setModalCadastro(false); redirect('/login')}}></ModalCadastro>

        </header>
    )
}

export default Menu