import md5 from "md5";

const url = process.env.REACT_APP_URL;
const url_storage = process.env.REACT_APP_URL + '/storage/';

let resgataToken = () => {return localStorage.getItem('token')}

const api = {

    login: async (email, password) => {
        const response = await fetch(`${url}/login/afiliado`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password,
                empresa: process.env.REACT_APP_EMPRESA
            })
        });
        return response.json();
    },

    cadastrar: async (dados) => {
        const response = await fetch(`${url}/afiliados/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...dados,
                password: md5(dados.password),
                empresa: process.env.REACT_APP_EMPRESA
            })
        });
        return response.json();
    },

    afiliado: {

        saldo: async (id) => {
            const response = await fetch(`${url}/afiliados/getSaldo`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': 'Bearer ' + resgataToken()
                },
                body: JSON.stringify({
                    id: id
                })
            });
            return response.json();
        },

        get: async (id) => {
            const response = await fetch(`${url}/afiliados/get`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': 'Bearer ' + resgataToken()
                },
                body: JSON.stringify({
                    id: id
                })
            });
            return response.json();
        },

        relatorio: async (afiliado) => {
            const response = await fetch(`${url}/afiliados/relatorio`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': 'Bearer ' + resgataToken()
                },
                body: JSON.stringify({
                    afiliado
                })
            });
            return response.json();
        },

        extrato: async (rifa, inicio, fim) => {
            const response = await fetch(`${url}/afiliados/extrato`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': 'Bearer ' + resgataToken()
                },
                body: JSON.stringify({
                    rifa,
                    inicio,
                    fim
                })
            });
            return response.json();
        }

    },

    saques: {

        solicitar: async (valor) => {
            const response = await fetch(`${url}/afiliados/solicitaSaque`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': 'Bearer ' + resgataToken()
                },
                body: JSON.stringify({
                    valor
                })
            });
            return response.json();
        },

        getAll: async () => {
            const response = await fetch(`${url}/afiliados/getSaques`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': 'Bearer ' + resgataToken()
                },
            });
            return response.json();
        },

        get: async (saque) => {
            const response = await fetch(`${url}/afiliados/getSaque`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': 'Bearer ' + resgataToken()
                },
                body: JSON.stringify({
                    saque
                })
            });
            return response.json();
        }

    },

    rifas: {

        getRifas: async () => {
            const response = await fetch(`${url}/rifas/getByEmpresaAtivas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    empresa: process.env.REACT_APP_EMPRESA
                })
            });
            return response.json();
        },

    },

    empresa: {

        get: async (empresa) => {
            const response = await fetch(`${url}/empresas/getcliente`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    empresa: empresa
                })
            });
            return response.json();
        }

    }

}

export { url, api, url_storage };