import React, {useState, useEffect} from "react";

import Toast from "../toast/Toast";

import LoadingSplash from '../LoadingSplash/LoadingSplash';
import MyTable from '../mytable/MyTable';

import { api } from '../../apis/connect';
import { useAuth } from '../../contexts/AuthContext';

import { mask_cpf, mask_money, mask_phone } from '../../utils/masks';

function TableExtrato(props) {

    const {usuarioLogado} = useAuth();

    const [loading, setLoading] = useState(false);

    const [rifas, setRifas] = useState([]);
    const [data, setData] = useState([]);

    const today = new Date();
    var today_str = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2,'0')}-${today.getDate().toString().padStart(2,'0')}`

    if (props?.inicio) {
        today_str = props.inicio.split(' ')[0];
    }

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    var tomorrow_str = `${tomorrow.getFullYear()}-${(tomorrow.getMonth() + 1).toString().padStart(2,'0')}-${tomorrow.getDate().toString().padStart(2,'0')}`

    if (props?.fim) {
        tomorrow_str = props.fim.split(' ')[0];
    }

    const [periodos, setPeriodos] = useState({inicio: today_str, fim: tomorrow_str});
    const [selectedRifa, setSelectedRifa] = useState(props?.rifa);

    useEffect(() => {

        setLoading(true);

        usuarioLogado?.id && api.rifas.getRifas().then(response => {
            
            if (response.error) {
                return;
            }

            setRifas(response);

        });

        usuarioLogado?.id && api.afiliado.extrato(selectedRifa, periodos.inicio, periodos.fim).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            setData(response);

            setLoading(false);

        })

    }, [periodos, selectedRifa, usuarioLogado?.id])

    const columns = [
        {Header: 'ID', accessor: 'id'},
        {Header: 'Cliente', accessor: 'nome'},
        {Header: 'Quantidade', accessor: 'quant'},
        {Header: 'Total', accessor: (row) => mask_money(row.total)},
        {Header: 'Comissão', accessor: (row) => `${mask_money(row.total_afiliado)} / ${row.afiliado_porcentagem}%`},
        {Header: 'Pago', accessor: (row) => row.paid ? 'Sim' : 'Não'},
        {Header: 'Data de pagamento', accessor: (row) => row.paid_at ? new Date(row.paid_at).toLocaleString('pt-br') : 'Não pago'},
        {Header: 'Data de criação', accessor: (row) => new Date(row.created_at).toLocaleString('pt-br')},
        {Header: 'Rifa', accessor: 'rifa', Cell: (row) => <span title={row.value}>{row.value?.slice(0,30)+'...'}</span>},
    ]

    return <>

        {!props?.rifa && <div className="row mt-2 mb-2">
            <div className="col">
                <label>Rifas:</label>
                <select className="form-control cursor" onChange={e => setSelectedRifa(e.target.value)}>
                    <option value=''>Todas</option>
                    {rifas && rifas.map(rifa => <option value={rifa.id}>{rifa.nome}</option>)}
                </select>
            </div>
        </div>}

        <div className="row mb-4">
            <div className="col">
                <label>De</label>
                <input type='date' className="form-control" value={periodos.inicio} onChange={e => setPeriodos({...periodos, inicio: e.target.value})}></input>
            </div>
            <div className="col">
                <label>Até</label>
                <input type='date' className="form-control" value={periodos.fim} onChange={e => setPeriodos({...periodos, fim: e.target.value})}></input>
            </div>
        </div>

        <div class='position-relative'>
            <MyTable columns={columns} data={data} />
            <LoadingSplash active={loading} absolute/>
        </div>
    
    </>

}

export default TableExtrato;