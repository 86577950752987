import React, {useState, useRef} from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import {mask_money} from '../../../utils/masks';

function ModalRelatorios(props) {

    return (


        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Relatórios
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div class='row'>

                    <div class='col-6'>
                        <label>Total hoje:</label>
                        <input type='text' class='form-control mt-2 text-center' value={mask_money(props?.totais?.total_hoje?.total)} readOnly/>
                    </div>

                    <div class='col-6'>
                        <label>Quantidade de vendas hoje:</label>
                        <input type='text' class='form-control mt-2 text-center' value={props?.totais?.total_hoje?.quantidade} readOnly/>
                    </div>

                    <div class='col-6 mt-4'>
                        <label>Total mês:</label>
                        <input type='text' class='form-control mt-2 text-center' value={mask_money(props?.totais?.total_mes?.total)} readOnly/>
                    </div>

                    <div class='col-6 mt-4'>
                        <label>Quantidade de vendas mês:</label>
                        <input type='text' class='form-control mt-2 text-center' value={props?.totais?.total_mes?.quantidade} readOnly/>
                    </div>

                    <div class='col-6 mt-4'>
                        <label>Total ano:</label>
                        <input type='text' class='form-control mt-2 text-center' value={mask_money(props?.totais?.total_ano?.total)} readOnly/>
                    </div>

                    <div class='col-6 mt-4'>
                        <label>Quantidade de vendas ano:</label>
                        <input type='text' class='form-control mt-2 text-center' value={props?.totais?.total_ano?.quantidade} readOnly/>
                    </div>

                    <div class='col-6 mt-4'>
                        <label>Total já obtido:</label>
                        <input type='text' class='form-control mt-2 text-center' value={mask_money(props?.totais?.total_tudo?.total)} readOnly/>
                    </div>

                    <div class='col-6 mt-4'>
                        <label>Quantidade de vendas já obtidas:</label>
                        <input type='text' class='form-control mt-2 text-center' value={props?.totais?.total_tudo?.quantidade} readOnly/>
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button className='btn-dark' onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>

        </Modal>
    );
}


export default ModalRelatorios;
