import React, {useState, useEffect, useRef} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/login.css'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import InputMask from 'react-input-mask';

import EmailIcon from '@mui/icons-material/Email';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

import LoadingSplash from '../components/LoadingSplash/LoadingSplash';
import TableExtrato from '../components/tableExtrato/TableExtrato';
import ModalRelatorios from '../components/modals/relatorios/ModalRelatorios';

import { api } from '../apis/connect';
import { useAuth } from '../contexts/AuthContext';
import { useEmpresa } from '../contexts/EmpresaContext';

import Toast from '../components/toast/Toast';

import {mask_money} from '../utils/masks';
import { Add } from '@mui/icons-material';

function Home() {

    const { empresa } = useEmpresa();
    const { usuarioLogado, token } = useAuth();

    const [rifas, setRifas] = useState([]);
    const [selectedRifa, setSelectedRifa] = useState(null);

    const [relatorio, setRelatorio] = useState({});

    const [saldo, setSaldo] = useState(0);

    const [loading, setLoading] = useState(false);
    const [loadingRelatorio, setLoadingRelatorio] = useState(false);

    const [showModalRelatorios, setShowModalRelatorios] = useState(false);

    useEffect(() => {

        empresa?.id && api.rifas.getRifas(empresa.id, token).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            setRifas(response);
            if (response.length > 0) setSelectedRifa(response[0]);

        })

        setLoading(true);

        usuarioLogado?.id && api.afiliado.saldo(usuarioLogado.id).then(response => {

            if (response.error) {
                Toast.error(response.error);
                setLoading(false);
                return;
            }

            setSaldo(response.saldo);
            setLoading(false);

        })

        setLoadingRelatorio(true);

        usuarioLogado?.id && api.afiliado.relatorio(usuarioLogado.id).then(response => {

            if (response.error) {
                Toast.error(response.error);
                setLoadingRelatorio(false);
                return;
            }

            setRelatorio(response);
            setLoadingRelatorio(false);

        })

    }, [empresa, usuarioLogado])

    function handlerCopyLink(link){

        console.log(link);

        navigator.clipboard.writeText(link).then(() => {
            Toast.success('Link copiado com sucesso!');
        }).catch(() => {
            Toast.error('Erro ao copiar link');
        });

    }

    function selectRifa(id){

        rifas.forEach(element => {

            if (element.id == id) {
                setSelectedRifa(element);
            }
            
        });

    }

    return(
        <div>

            <section class="tela-1 px-4 mt-5 mt-sm-2">

                <div class='row justify-content-center'>

                    {/* <div class='col-sm-3 me-4'>
                        <h3>Dashboard</h3>
                        <p>Seja bem-vindo ao painel de controle de afiliado</p>
                    </div> */}

                    <div class='col-sm-3 col-md-2 card me-sm-4 mb-2'>
                        <div class="card-body">
                            <h5 class="card-title fs-4">{mask_money(saldo)}</h5>
                            <p class="card-text small">Saldo</p>
                        </div>
                    </div>

                    <div class='col-sm-3 col-md-2 card me-sm-4 mb-2'>
                        <div class="card-body">
                            <h5 class="card-title fs-4">{relatorio?.totais?.total_mes?.quantidade}</h5>
                            <p class="card-text small">Quantidade de vendas</p>
                        </div>
                    </div>

                    <div class='col-sm-3 col-md-2 card me-sm-4 mb-2'>
                        <div class="card-body">
                            <h5 class="card-title fs-4">{mask_money(relatorio?.totais?.total_mes?.total)}</h5>
                            <p class="card-text small">Total (mês)</p>
                        </div>
                    </div>

                    <div class='col-sm-3 col-md-2 card me-sm-4 mb-2'>
                        <div class="card-body">
                            <h5 class="card-title fs-4">{mask_money(relatorio?.totais?.total_tudo?.total)}</h5>
                            <p class="card-text small">Total já obtido</p>
                        </div>
                    </div>

                </div>

                <div class="d-flex mt-4">
                    <button class='btn rounded-0 mx-auto btn-outline-success' onClick={() => setShowModalRelatorios(true)}><Add fontSize='15px' className='me-2'/>Relatórios</button>
                </div>

                <div class='row justify-content-center mt-4'>

                    <div class='col-sm-6 text-center'>
                        <div class='fs-5 mb-2'>Selecione a rifa:</div>
                        <select class="form-select mx-auto text-center" onChange={e => selectRifa(e.target.value)}>
                            {rifas && rifas.map(rifa => <option value={rifa.id}>{rifa.nome}</option>)}
                        </select>
                    </div>

                    <div class='col-12 text-center mt-5'>

                        <div class='row justify-content-center'>

                            <div class='col-12'>
                                Seu link de afiliado para a rifa selecionada é:
                            </div>

                            <div class='col-12 col-sm-6'>
                                <input 
                                type='text' 
                                class='form-control mx-auto mt-2 text-center' 
                                value={`${empresa?.domain}/rifa/${selectedRifa?.slug}?code=${usuarioLogado?.codigo}`} 
                                onClick={() => handlerCopyLink(`${empresa?.domain}/rifa/${selectedRifa?.slug}?code=${usuarioLogado?.codigo}`)}
                                disabled readOnly
                                />
                                <button class='btn btn-success mt-2' onClick={() => handlerCopyLink(`${empresa?.domain}/rifa/${selectedRifa?.slug}?code=${usuarioLogado?.codigo}`)}>Copiar</button>
                            </div>

                        </div>
                    </div>

                </div>

                <div class='card border-0 mt-5 p-0 p-sm-5'>
                    <div class='card-title fs-4 text-center'>Extrato</div>
                    <TableExtrato/>
                </div>


            </section>

            <LoadingSplash active={loading}/>

            <ModalRelatorios show={showModalRelatorios} onHide={() => setShowModalRelatorios(false)} totais={relatorio?.totais}/>

        </div>
    )
}

export default Home