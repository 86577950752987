// EmpresaContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

import { api } from '../apis/connect';
import { Toast } from 'bootstrap';

const EmpresaContext = createContext();

export const useEmpresa = () => {
    return useContext(EmpresaContext);
};

export const EmpresaProvider = ({ children }) => {
    const [empresa, setEmpresa] = useState(null);

    useEffect(() => {
        
        api.empresa.get(process.env.REACT_APP_EMPRESA).then(res => {

            if (res?.error) {
                Toast.error(res.error);
                return
            }

            setEmpresa(res);

        })

    }, []);

    const empresaContextValue = {
        empresa
    };

    return (
        <EmpresaContext.Provider value={empresaContextValue}>
            {children}
        </EmpresaContext.Provider>
    );
};
