import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';

// pages
import Login from './pages/Login.js';
import Home from './pages/Home.js';
import Saques from './pages/Saques.js';

// components
import Footer from './components/footer/Footer.js';
import Menu from "./components/menu/Menu.js";

// context
import { AuthProvider, isAuthenticaded} from './contexts/AuthContext.js';
import { EmpresaProvider } from './contexts/EmpresaContext.js';

function PrivateRoute(props) {
  return isAuthenticaded() ? <>
      <Menu/>

      <div class="rh-bg-secundary mt-5">
        <Outlet/>
      </div>

      <Footer/>

    </> : <Navigate to='/login'/>
}

function App() {

  return (
    <BrowserRouter>
      <AuthProvider>
        <EmpresaProvider>
          <Routes>
            <Route path='/' element={<PrivateRoute/>}>
              <Route path='/' element={<Home/>}></Route>
              <Route path='/solicitacoes-de-saques' element={<Saques/>}></Route>
            </Route>

            <Route path='/login' element={<><Menu/><Login/><Footer/></>}></Route>
            <Route path='*' element={<Navigate to='/'/>}></Route>
          </Routes>
        </EmpresaProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;